<template>
  <div class="content-wrapper view-aptlist-manage pt-3">
    <div class="content">
      <div class="card">
        <div class="card-body">
          <h4><i class="fas fa-list"></i> ลิสต์การรักษา</h4>
          <div class="row mx-1 my-3">
            <b-form-input
              type="search"
              class="col required"
              autofocus
              v-model="filter"
              placeholder="ค้นหารายการนัดหมายจากชื่อ หรือประเภท"
            ></b-form-input>
            <b-button
              v-if="isCounterOrManager"
              class="col-5 col-sm-4 col-md-3 ml-3"
              variant="primary"
              @click="openTxItemModal()"
            >
              <i class="fas fa-plus"></i> เพิ่มรายการ
            </b-button>
          </div>
          <b-table
            id="treatmentListManageTable"
            ref="treatmentListManageTable"
            hover
            small
            sort-icon-left
            head-variant="light"
            :responsive="true"
            sort-by="id"
            :sort-desc="true"
            :items="treatList"
            :fields="fields"
            :filter="filter"
          >
            <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
            <template v-slot:cell(creationDt)="row">{{
              formatDate(row.value)
            }}</template>
            <template v-slot:cell(buttons)="row">
              <b-button-group class="show-when-hovered" size="xs">
                <b-button
                  variant="outline-info"
                  :pressed="row.item.favorite"
                  @click="favToggle(row.item)"
                >
                  <i class="far fa-star"></i>
                </b-button>
                <b-button
                  v-if="isCounterOrManager && !row.item.nhso"
                  variant="outline-secondary"
                  @click="openTxItemModal(row.item)"
                >
                  <i class="fas fa-edit"></i>
                </b-button>
                <b-button
                  v-if="isCounterOrManager && !row.item.nhso"
                  variant="outline-danger"
                  @click="deleteTxItem(row.item)"
                >
                  <i class="fas fa-trash-alt"></i>
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>
      </div>
      <TxItem ref="TxItemModal"></TxItem>
      <Dialog ref="Dialog"></Dialog>
      <Loading v-if="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import TxItem from "@/components/modal/TxItem";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

export default {
  name: "TxListManage",
  components: {
    TxItem,
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "index",
          label: "",
          // variant: "secondary",
          class: "text-center",
        },
        {
          key: "code",
          label: "รหัส",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "nameTh",
          label: "รายการรักษา",
          sortable: true,
          tdClass: "w-25",
          class: "narrow-spacing",
        },
        {
          key: "nameEn",
          label: "รายการรักษา(Eng)",
          sortable: true,
          tdClass: "w-25",
          class: "narrow-spacing",
        },
        {
          key: "cateName",
          label: "ประเภท",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "price",
          label: "ราคา",
        },
        {
          key: "creationDt",
          label: "วันที่สร้าง",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "buttons",
          label: "",
          class: "text-center",
        },
      ],
      filter: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      getTreatList: "moduleAppointment/getTreatList",
      getTreatCateBy: "moduleAppointment/getTreatCateBy",
      isAccessMenu: "moduleUser/getIsAccessMenu",
    }),
    treatList() {
      const treatList = [];
      this.getTreatList.forEach((element) => {
        element.cateName = this.getTreatCateName(element);
        // element.creationDtShow = moment(element.creationDt)
        // .locale("th")
        // .add(543, "year").format('DD MMM YY');
        treatList.push(element);
      });
      return treatList;
    },
    isCounterOrManager() {
      return this.isAccessMenu(["COUNTER", "MANAGER", "HEADCOUNTER"]);
    },
  },
  watch: {},
  methods: {
    ...mapActions({}),
    init() {},
    moment() {
      return moment();
    },
    openTxItemModal(txItem) {
      this.$refs.TxItemModal.show(txItem);
    },
    favToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateTreatFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteTreatFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    deleteTxItem(eventData) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบการรักษานี้ ?",
        "คุณต้องการลบการรักษานี้"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("moduleAppointment/deleteTreatList", {
              event: eventData,
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
            })
            .then((res) => {
              if (res.status == "200") {
                this.isLoading = false;
                this.showDialogToast(
                  "success",
                  `ลบรายการ "${eventData.nameTh}" สำเร็จ`
                );
              }
            })
            .catch(() => {
              this.isLoading = false;
              this.showDialogToast(
                "warning",
                `ลบรายการ "${eventData.nameTh}" ไม่สำเร็จ`
              );
            })
            .finally(() => {
              this.$bvModal.hide("TxItemModal");
            });
        }
      });
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    getTreatCateName(val) {
      if (this.getTreatCateBy(val.cateId, "id")) {
        return this.getTreatCateBy(val.cateId, "id").nameTh;
      } else {
        return "";
      }
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
  },
  updated() {},
  destroyed() {
    this.$store.dispatch("moduleAppointment/fetchTreatmentCateAndList", {
      clinicUrl: this.$route.params.clinicUrl,
      branchUrl: this.$route.params.branchUrl,
    });
  },
};
</script>

<style scoped>
</style>
