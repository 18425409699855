<template>
  <b-modal
    modal-class="component-modal-txitem"
    id="TxItemModal"
    ref="TxItemModal"
    size="lg"
    title="รายการรักษา"
    hide-footer
    scrollable
    no-close-on-backdrop
    @close="close"
    @hide="close"
    @hidden="setDefaultValue"
  >
    <b-form @submit.prevent="saveTxItem">
      <b-form-group label-cols="3" label="รหัส:" label-align="right">
        <b-form-input v-model="tx.code" class> </b-form-input>
      </b-form-group>
      <b-form-group label-cols="3" label="ชื่อรายการ(ไทย):" label-align="right">
        <b-form-input
          v-model="tx.nameTh"
          class="required"
          :class="{ 'is-invalid': $v.tx.nameTh.$error }"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group label-cols="3" label="ชื่อรายการ(Eng):" label-align="right">
        <b-form-input v-model="tx.nameEn"></b-form-input>
      </b-form-group>

      <b-form-row>
        <div class="col-3 text-right align-self-center">ประเภท:</div>
        <b-form-select
          v-model="tx.cateId"
          class="col mx-1 required"
          :options="getTreatCateList"
          value-field="id"
          text-field="nameTh"
          :class="{ 'is-invalid': $v.tx.cateId.$error }"
        >
          <template v-slot:first>
            <b-form-select-option :value="null" disabled
              >-- เลือกประเภท --</b-form-select-option
            >
          </template>
        </b-form-select>
        <b-button
          class="col-1 mx-1"
          block
          variant="secondary"
          @click="openTxCateItem()"
        >
          <i class="fas fa-edit"></i>
        </b-button>
      </b-form-row>
      <hr />
      <b-form-group label-cols="3" label="ราคา:" label-align="right">
        <b-input-group>
          <b-form-input
            number
            v-model.number="tx.price"
            class="required"
            :class="{ 'is-invalid': $v.tx.price.$error }"
          >
          </b-form-input>
          <template v-slot:append>
            <b-input-group-text>บาท</b-input-group-text>
          </template>
        </b-input-group>
      </b-form-group>

      <b-form-group label-cols="3" label="DF รายการรักษา:" label-align="right">
        <b-row>
          <b-col cols="12" lg="4">
            <b-input-group>
              <b-form-input
                v-model.number="tx.df"
                number
                class="required"
                @keyup="validateDf(tx.df)"
                :class="{ 'is-invalid': $v.tx.df.$error }"
              ></b-form-input>
              <template v-slot:append>
                <b-input-group-text>{{
                  tx.dfType == 0 ? "%" : "บาท"
                }}</b-input-group-text>
              </template>
            </b-input-group>
          </b-col>
          <b-col class="align-self-center">
            <b-form-radio-group v-model="tx.dfType">
              <b-form-radio :value="0">Percent</b-form-radio>
              <b-form-radio :value="1">Fixed</b-form-radio>
            </b-form-radio-group>
          </b-col>

          <b-col class="align-self-center" cols="5" lg="3">
            <b-button block v-b-toggle.dfDetail variant="secondary" size=""
              >DF เฉพาะท่าน</b-button
            >
          </b-col>
        </b-row>
      </b-form-group>
      <b-row>
        <b-col class="px-2" offset-lg="3">
          <b-collapse id="dfDetail" class="mr-2">
            <b-card>
              <div class="mb-3">
                กรุณาเลือกแพทย์ที่ต้องการให้ DF เฉพาะท่าน
                <br />ในกรณีที่ไม่ได้เลือก DF จะคิดเท่ากับ "DF รายการรักษา"
              </div>
              <div
                class="mb-2"
                v-for="(dfSpec, key, index) in tx.dfDoctors"
                :key="dfSpec.uid"
              >
                <b-row v-if="getDoctorListBy(dfSpec.doctorUid, 'uid')">
                  <b-col cols="6" class="pr-0">
                    <div class="text-right pt-1">
                      {{ getDoctorListBy(dfSpec.doctorUid, "uid").fullName }} :
                    </div>
                  </b-col>
                  <b-col>
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <!-- เพิ่ม data dfActive อีกตัว?? -->
                        <b-form-checkbox
                          switch
                          v-model="dfSpec.dfActive"
                          @input="activateDfSpec(dfSpec)"
                        ></b-form-checkbox>
                      </b-input-group-prepend>
                      <b-form-input
                        number
                        v-model="dfSpec.df"
                        @keyup="validateDfItem(dfSpec, index)"
                        :disabled="!dfSpec.dfActive"
                        required
                      ></b-form-input>
                      <b-input-group-append>
                        <b-input-group-text>{{
                          tx.dfType == 0 ? "%" : "บาท"
                        }}</b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>
              </div>

              <!-- <b-form-group
                class="mb-1"
                v-for="(doctor,key,index) in tx.dfDoctor"
                :key="index"
                label-cols="6"
                :label="getDoctorListBy(doctor.doctorUid,'value').label + ' : '"
                label-align="right"
              >
                <b-row>
                  <b-input-group class="col">
                    <b-form-input
                      type="number"
                      v-model="doctor.df"
                      @keyup="validateDfItem(doctor.df,index)"
                    ></b-form-input>
                    <template v-slot:append></template>
                  </b-input-group>
                </b-row>
              </b-form-group>-->
            </b-card>
          </b-collapse>
        </b-col>
      </b-row>
      <hr />
      <b-row class="d-flex flex-row-reverse">
        <div>
          <b-button class="ml-2 mr-3" type="submit" variant="primary">{{
            tx.id ? "แก้ไขรายการ" : "สร้างรายการ"
          }}</b-button>
        </div>
        <div>
          <b-button variant="danger" v-if="tx.id" @click="deleteTxItem(tx)"
            >ลบรายการ</b-button
          >
        </div>
      </b-row>
    </b-form>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
    <CateManage
      ref="TxCateItem"
      title="ประเภทการรักษา"
      :categoryList="getTreatCateList"
      :createCategoryFn="createTreatmentCate"
      :deleteCategoryFn="deleteTreatmentCate"
      :updateCategoryFn="updateTreatmentCate"
      :fetchCategoryFn="fetchTreatCateList"
    />
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import CateManage from "@/components/modal/CateManage";
import { mapActions, mapGetters } from "vuex";
import {
  required,
  requiredIf,
  numeric,
  minLength,
  between,
  sameAs,
  not,
  helpers,
  email,
} from "vuelidate/lib/validators";
import "@/theme/modal/AptItem.scss";

export default {
  name: "TxItemModal",
  components: {
    Dialog,
    Loading,
    CateManage,
  },
  data() {
    return {
      isLoading: false,
      tx: {
        id: null,
        code: "",
        nameTh: "",
        nameEn: "",
        cateId: null,
        favorite: false,
        price: 0,
        df: 50,
        dfType: 0,
        dfDoctors: [],
      },
    };
  },
  validations: {
    tx: {
      nameTh: { required },
      price: { required },
      cateId: { required },
      df: { required },
    },
  },
  computed: {
    ...mapGetters({
      getTreatCateList: "moduleAppointment/getTreatCateList",
      getDoctorList: "moduleUser/getDoctorList",
      getDoctorListBy: "moduleUser/getDoctorListBy",
      branchInfo: "moduleUser/getBranchInfo",
    }),
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    ...mapActions({
      createTreatmentCate: "moduleAppointment/createTreatmentCate",
      updateTreatmentCate: "moduleAppointment/updateTreatmentCate",
      deleteTreatmentCate: "moduleAppointment/deleteTreatmentCate",
      fetchTreatCateList: "moduleAppointment/fetchTreatCateList",
    }),
    init() {},
    show(txItem) {
      if (txItem) {
        this.isLoading = true;
        this.$store
          .dispatch("moduleAppointment/findTreatListById", {
            event: txItem,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            if (res.status == 200) {
              this.tx = Object.assign({}, res.data);
              this.$bvModal.show("TxItemModal");
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.setDfDoctor();
          });
      } else {
        this.tx.df = this.branchInfo.df;
        this.setDfDoctor();
        this.$bvModal.show("TxItemModal");
      }
    },
    saveTxItem() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      this.tx.dfDoctors = this.tx.dfDoctors.filter((i) => i.dfActive);
      if (!this.tx.id) {
        delete this.tx.id;
        this.$store
          .dispatch("moduleAppointment/createTreatList", {
            event: this.tx,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$parent.showDialogToast(
                "success",
                `สร้างรายการ "${this.tx.nameTh}" สำเร็จ`
              );
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.$bvModal.hide("TxItemModal");
            this.setDefaultValue();
          });
      } else {
        this.$store
          .dispatch("moduleAppointment/updateTreatList", {
            event: this.tx,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            this.$parent.showDialogToast(
              "success",
              `แก้ไขรายการ "${this.tx.nameTh}" สำเร็จ`
            );
          })
          .finally(() => {
            this.isLoading = false;
            this.$bvModal.hide("TxItemModal");
            this.setDefaultValue();
          });
      }
    },
    deleteTxItem(eventData) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบการรักษานี้ ?",
        "คุณต้องการลบการรักษานี้"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("moduleAppointment/deleteTreatList", {
              event: eventData,
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
            })
            .then((res) => {
              if (res.status == "200") {
                this.setDefaultValue();
                this.$parent.showDialogToast(
                  "success",
                  `ลบรายการ "${eventData.nameTh}" สำเร็จ`
                );
              }
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$parent.showDialogToast(
                "warning",
                `ลบรายการ "${eventData.nameTh}" ไม่สำเร็จ`
              );
            })
            .finally(() => {
              this.$bvModal.hide("TxItemModal");
            });
        }
      });
    },
    close() {
      this.$v.$reset();
      this.setDefaultValue();
    },
    setDefaultValue() {
      this.tx = Object.assign(
        {},
        {
          id: null,
          code: "",
          nameTh: "",
          nameEn: "",
          cateId: null,
          favorite: false,
          price: 0,
          df: 50,
          dfType: 0,
          dfDoctors: [],
        }
      );
    },
    setDfDoctor() {
      let doctorList = this.getDoctorList.map((doctor) => {
        let dfActive = false;
        let df = null;
        let index = this.tx.dfDoctors?.findIndex((ds) => {
          return ds.doctorUid === doctor.uid;
        });
        if (index >= 0) {
          df = this.tx.dfDoctors[index].df;
          dfActive = true;
        }
        return {
          doctorUid: doctor.uid,
          df,
          dfActive,
        };
      });
      this.tx.dfDoctors = [...doctorList];
    },
    openTxCateItem() {
      this.$refs.TxCateItem.show();
    },
    validateDf(df) {
      if (this.tx.dfType == 0) {
        if (df > 100) {
          this.tx.df = 100;
        }
      } else {
        if (df > this.tx.price) {
          this.tx.df = this.tx.price;
        }
      }
    },
    validateDfItem(dfSpec, index) {
      if (this.tx.dfType == 0) {
        if (dfSpec.df > 100) {
          dfSpec.df = 100;
        }
      } else {
        if (dfSpec.df > this.tx.price) {
          dfSpec.df = this.tx.price;
        }
      }
    },
    activateDfSpec(dfSpec) {
      if (!dfSpec.dfActive) {
        dfSpec.df = null;
      }
    },
  },
};
</script>

<style>
.required {
  background-color: #ccecff6c;
}
</style>